import { useState } from 'react'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext'

import Roles from '../enums/Roles'

const useAuth = () => {
    const [isTokenRefreshing, setIsTokenRefreshing] = useState(false)
    const { token, setToken } = useContext(AuthContext)
    const navigate = useNavigate()

    const isLoggedIn = () => {
        if (!token) {
            return false
        }

        return true
    }

    const parseTokenPayload = token => {
        try {
            const payloadInBase64 = token?.split('.')[1]
            const payloadInJson = JSON.parse(atob(payloadInBase64))

            return payloadInJson
        } catch (err) {
            console.error(err)
        }
    }

    const getUserRole = () => {
        const tokenPayload = parseTokenPayload(token?.access_token)
        return tokenPayload.role
    }

    const getEmailVerified = taxisToken => {
        // If connecting with taxisNet, use taxis token to redirect to profile if mail not verified
        const tokenPayload = taxisToken
            ? parseTokenPayload(taxisToken?.access_token)
            : parseTokenPayload(token?.access_token)
        return tokenPayload.emailVerified
    }

    const getCurrentUserID = () => {
        const tokenPayload = parseTokenPayload(token.access_token)
        return tokenPayload.sub
    }

    const isAdmin = () => {
        if (!isLoggedIn()) {
            return false
        }

        return getUserRole() === Roles.admin
    }

    const taxisLogin = async taxisToken => {
        try {
            // Check if there is already an active session
            if (isLoggedIn()) {
                navigate('/')
            }

            const jsonToken = JSON.parse(atob(taxisToken))

            sessionStorage.setItem('govpay-jwt', JSON.stringify(jsonToken))
            setToken(jsonToken)
            // navigate('/')

            window.href.url = `${process.env.REACT_APP_API_URL}/auth/taxis/logout`
        } catch (err) {
            // console.error(err)
            return
        }
    }

    const login = async (email, password, isAdmin = false) => {
        const dir = isAdmin ? '/admin' : '/'

        if (!email || !password) {
            return
        }

        try {
            // Check if there is alreay an active session
            if (isLoggedIn()) {
                navigate(dir)
            }

            const res = await fetch(
                `${process.env.REACT_APP_API_URL}/auth/jwt/login`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email: email,
                        password: password,
                    }),
                }
            )

            const data = await res.json()

            if (data.statusCode >= 400 && data.statusCode < 500) {
                return
            }

            sessionStorage.setItem('govpay-jwt', JSON.stringify(data))
            setToken(data)
            navigate(dir)
        } catch (err) {
            console.error(err)
        }
    }

    const refreshToken = async () => {
        if (!token) {
            navigate('/login', { replace: true })
        }

        try {
            setIsTokenRefreshing(true)

            const res = await fetch(
                `${process.env.REACT_APP_API_URL}/auth/refresh`,
                {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token.refresh_token}`,
                    },
                }
            )

            const data = await res.json()

            // Reset the state and storage token with the new values for the AuthContext check to work properly
            setToken(data)
            sessionStorage.setItem('govpay-jwt', JSON.stringify(data))
        } catch (err) {
            console.error(err)
        } finally {
            setIsTokenRefreshing(false)
        }
    }

    const logout = async () => {
        try {
            await fetch(`${process.env.REACT_APP_API_URL}/auth/logout`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${token.access_token}`,
                },
            })
        } catch (err) {
            console.error(err)
        } finally {
            setToken(null)
            sessionStorage.removeItem('govpay-jwt')
            navigate('/')
        }
    }

    return {
        isLoggedIn,
        isAdmin,
        parseTokenPayload,
        getUserRole,
        getEmailVerified,
        login,
        taxisLogin,
        refreshToken,
        isTokenRefreshing,
        logout,
        getCurrentUserID,
    }
}

export default useAuth
